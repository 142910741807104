<template>
  <div class="order">
    <title-top>订单确认</title-top>
    <div class="row flex-start bg-white">
      <img class="w100 bg-white" src="~images/orderban.png" alt />
    </div>
    <div class="row between align-center bg-white h210" @click="onAddr">
      <div class="column font24 f-999999 ml25">
        <div class="row mb20">
          <span>收货人：{{addres.contact_name}}</span>
          <span class="ml40">{{addres.mobile}}</span>
        </div>
        <span>{{addres.address}}</span>
      </div>
      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="row flex-start bg-white">
      <img class="w100 bg-white" src="~images/orderban.png" alt />
    </div>
    <div class="column bg-white mb20" v-for="(item,index) in res.items" :key="index">
      <div class="row">
        <img class="imgsize176 ml25 mr25 mt20" :src="item.image_url" alt />
        <div class="column mr25 mt40">
          <p class="flex1 ellipsis font24 f-1A1A1A">{{item.product_name}}</p>
          <div class="row between">
            <span class="font28 f-333333">¥{{item.price}}</span>
            <span class="font32 f-EE7C3E">×{{item.quantity}}</span>
          </div>
        </div>
      </div>
      <span class="row font24 f-333333 flex-end h72 mr25">共{{res.items.length}}种商品</span>
    </div>
    <div class="row flex-center bg-white h96">
      <span class="font24 f-333333 ml25">买家留言：</span>
      <input class="flex1 mr25 font24 f-999999 ml16" type="text" v-model="remark" placeholder="填写内容已和卖家协商确认" />
    </div>
    <div class="h118"></div>
    <div
      class="row between bg-white align-center position_f w100 h98"
      style="bottom:0;max-width:750px;"
    >
      <span class="font36 f-F62341 ml25">¥{{res.total_amount}}</span>
      <span class="row flex-center font32 f-white bg-F62341 br28 mr25 tjbtn" @click="onPay">提交订单</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {
  addressList,
  createOrder,
  payOrder,
  createOrderNow,
  payOrderNow
} from "network/igou2";

export default {
  name: "Order",
  data() {
    return {
      remark:'',
      addres: {},
      res: {},
      address_id: "",
      sku_id: this.$route.query.sku_id,
      num: this.$route.query.num,
      cart_item_ids:this.$route.query.cart_item_ids
    };
  },
  mounted() {
    this._addressList();
  },
  methods: {
    _addressList(page, size) {
      addressList(page, size).then(result => {
        console.log(result);
        if (result.data.length > 0) {
          this.addres = result.data[0];
          this.address_id = result.data[0].id;
          if (this.sku_id && this.num) {
            this._createOrderNow(this.address_id);
          } else {
            this._createOrder(this.address_id);
          }
        } else {
          this.$router.push("/zyaddr");
        }
      });
    },
    _createOrderNow(address_id) {
      createOrderNow(address_id, this.sku_id, this.num,this.remark).then(res => {
        console.log(res);
        this.res = res.data;
      });
    },
    _createOrder(address_id) {
      createOrder(address_id,this.cart_item_ids).then(res => {
        console.log(res);
        this.res = res.data;
      });
    },
    onAddr() {
      this.$router.push("/zyorderaddr");
    },
    cbApp(obj){
      //console.log(obj);
      window.webkit.messageHandlers.cbMessage.postMessage(
          JSON.stringify(obj)
        );
    },
    onPay() {
      this._payOrder(this.address_id);
    },
    _payOrder(address_id) {
      if (this.sku_id && this.num) {//立即购买
        payOrderNow(address_id, this.sku_id, this.num).then(res => {
          //console.log(res);
          let result = res.data.orders[0];
          if (result.state == "unpaid") {
            this.cbApp({objectId:result.id,link_type:'order_pay'});
            return;
            this.$router.push({
              path: "/zypayorder",
              query: { order_id: result.id }
            });
          } else {
            this.$toast.show("该订单" + result.state_zh_cn);
          }
        });
      } else {
        payOrder(address_id,this.cart_item_ids).then(res => {
          //console.log(res);
          let result = res.data.orders[0];
          if (result.state == "unpaid") {
            this.cbApp({objectId:result.id,link_type:'order_pay'});
            return;
            this.$router.push({
              path: "/zypayorder",
              query: { order_id: result.id }
            });
          } else {
            this.$toast.show("该订单" + result.state_zh_cn);
          }
        });
      }
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.tjbtn {
  width: 2.666667rem;
  height: 0.933333rem;
}
</style>
